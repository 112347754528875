import useTranslation from 'next-translate/useTranslation';

import type { ChannelCategoryType } from 'types/channel';
import type {
  LeagueType,
  PositionUnionType,
  StatusType,
} from 'types/matchData';
import type { SportType } from 'types/scoreboardData';
import type { MemberType } from 'types/team';
import type { VisibilityType } from 'types/visibility';

const useTranslationList = () => {
  const { t } = useTranslation('scoreboard');
  const { t: event_t } = useTranslation('event');
  const { t: settings_t } = useTranslation('settings');
  const { t: common_t } = useTranslation('common');

  const getPositionMenuList = (
    type: string,
  ): { value: PositionUnionType; content: string }[] => {
    switch (type) {
      case 'baseball':
        return [
          { value: 'default', content: common_t('position.unselected') },
          { value: 'P', content: common_t('position.baseball.P') },
          { value: 'C', content: common_t('position.baseball.C') },
          { value: '1B', content: common_t('position.baseball.1B') },
          { value: '2B', content: common_t('position.baseball.2B') },
          { value: '3B', content: common_t('position.baseball.3B') },
          { value: 'SS', content: common_t('position.baseball.SS') },
          { value: 'LF', content: common_t('position.baseball.LF') },
          { value: 'CF', content: common_t('position.baseball.CF') },
          { value: 'RF', content: common_t('position.baseball.RF') },
          { value: 'DH', content: common_t('position.baseball.DH') },
        ];
      case 'basketball':
        return [
          { value: 'default', content: common_t('position.unselected') },
          { value: 'PG', content: common_t('position.basketball.PG') },
          { value: 'SG', content: common_t('position.basketball.SG') },
          { value: 'PF', content: common_t('position.basketball.PF') },
          { value: 'SF', content: common_t('position.basketball.SF') },
          { value: 'C', content: common_t('position.basketball.C') },
        ];

      case 'cricket':
        return [
          { value: 'default', content: common_t('position.unselected') },
          { value: 'WK', content: common_t('position.cricket.WK') },
        ];

      case 'futsal':
        return [
          { value: 'default', content: common_t('position.unselected') },
          { value: 'GO', content: common_t('position.futsal.GO') },
          { value: 'PI', content: common_t('position.futsal.PI') },
          { value: 'AL', content: common_t('position.futsal.AL') },
          { value: 'FI', content: common_t('position.futsal.FI') },
        ];

      case 'handball':
        return [
          { value: 'default', content: common_t('position.unselected') },
          { value: 'GK', content: common_t('position.handball.GK') },
          { value: 'CB', content: common_t('position.handball.CB') },
          { value: 'RB', content: common_t('position.handball.RB') },
          { value: 'LB', content: common_t('position.handball.LB') },
          { value: 'RW', content: common_t('position.handball.RW') },
          { value: 'LW', content: common_t('position.handball.LW') },
          { value: 'PV', content: common_t('position.handball.PV') },
        ];

      case 'rugby':
        return [
          { value: 'default', content: common_t('position.unselected') },
          { value: 'PR', content: common_t('position.rugby.PR') },
          { value: 'HK', content: common_t('position.rugby.HK') },
          { value: 'SR', content: common_t('position.rugby.SR') },
          { value: 'FL', content: common_t('position.rugby.FL') },
          { value: 'N8', content: common_t('position.rugby.N8') },
          { value: 'SH', content: common_t('position.rugby.SH') },
          { value: 'FH', content: common_t('position.rugby.FH') },
          { value: 'IC', content: common_t('position.rugby.IC') },
          { value: 'OC', content: common_t('position.rugby.OC') },
          { value: 'WG', content: common_t('position.rugby.WG') },
          { value: 'FB', content: common_t('position.rugby.FB') },
        ];

      case 'soccer':
        return [
          { value: 'default', content: common_t('position.unselected') },
          { value: 'GK', content: common_t('position.soccer.GK') },
          { value: 'DF', content: common_t('position.soccer.DF') },
          { value: 'MF', content: common_t('position.soccer.MF') },
          { value: 'FW', content: common_t('position.soccer.FW') },
        ];

      case 'volleyball':
        return [
          { value: 'default', content: common_t('position.unselected') },
          { value: 'OH', content: common_t('position.volleyball.OH') },
          { value: 'OPP', content: common_t('position.volleyball.OPP') },
          { value: 'C', content: common_t('position.volleyball.C') },
          { value: 'S', content: common_t('position.volleyball.S') },
          { value: 'Li', content: common_t('position.volleyball.Li') },
        ];

      case 'iceHockey':
        return [
          { value: 'default', content: common_t('position.unselected') },
          { value: 'F', content: common_t('position.iceHockey.F') },
          { value: 'D', content: common_t('position.iceHockey.D') },
          { value: 'G', content: common_t('position.iceHockey.G') },
        ];

      default:
        return [{ value: 'default', content: common_t('position.unselected') }];
    }
  };

  const getSortingOptionList = () => [
    { value: 'latest', content: t('sorts.latest') },
    { value: 'view', content: t('sorts.view') },
  ];

  const getDateRangeOptionList = () => [
    { value: 'all', content: t('dateRange.all') },
    { value: 'today', content: t('dateRange.today') },
    { value: 'pastWeek', content: t('dateRange.pastWeek') },
    { value: 'pastMonth', content: t('dateRange.pastMonth') },
    { value: 'pastYear', content: t('dateRange.pastYear') },
    { value: 'customRange', content: t('dateRange.customRange') },
  ];

  const getSportTypeList = (): { value: SportType; content: string }[] => [
    { value: 'universal', content: t('sportType.universal') },
    { value: 'baseball', content: t('sportType.baseball') },
    { value: 'basketball', content: t('sportType.basketball') },
    { value: 'soccer', content: t('sportType.soccer') },
    { value: 'pickleball', content: t('sportType.pickleball') },
    { value: 'taekwondo', content: t('sportType.taekwondo') },
    { value: 'tennis', content: t('sportType.tennis') },
    { value: 'futsal', content: t('sportType.futsal') },
    { value: 'tableTennis', content: t('sportType.tableTennis') },
    { value: 'volleyball', content: t('sportType.volleyball') },
    { value: 'badminton', content: t('sportType.badminton') },
    { value: 'hockey', content: t('sportType.hockey') },
    { value: 'iceHockey', content: t('sportType.iceHockey') },
    { value: 'cricket', content: t('sportType.cricket') },
    { value: 'billiard', content: t('sportType.billiard') },
    { value: 'handball', content: t('sportType.handball') },
    { value: 'jokgu', content: t('sportType.jokgu') },
    { value: 'golf', content: t('sportType.golf') },
    { value: 'squash', content: t('sportType.squash') },
    { value: 'football', content: t('sportType.football') },
    { value: 'sepakTakraw', content: t('sportType.sepakTakraw') },
    { value: 'athletics', content: t('sportType.athletics') },
    { value: 'esports', content: t('sportType.esports') },
    { value: 'bodybuilding', content: t('sportType.bodybuilding') },
    { value: 'climbing', content: t('sportType.climbing') },
    { value: 'rugby', content: t('sportType.rugby') },
  ];

  const getStatusList = (): { value: StatusType; content: string }[] => [
    { value: 'schedule', content: t('statusList.schedule') },
    { value: 'live', content: t('statusList.live') },
    { value: 'end', content: t('statusList.end') },
  ];

  const getEventTypeList = () => [
    { value: 'universal', content: event_t('list.eventType.universal') },
    { value: 'sport', content: event_t('list.eventType.sport') },
    { value: 'seminar', content: event_t('list.eventType.seminar') },
  ];

  const getEventEnvironmentList = () => [
    { value: 'online', content: event_t('list.eventEnvironment.online') },
    { value: 'offline', content: event_t('list.eventEnvironment.offline') },
    {
      value: 'on/offline',
      content: event_t('list.eventEnvironment.on/offline'),
    },
  ];

  const getEventLeagueTypeList = () => [
    { value: 'child', content: event_t('leagueType.child') },
    {
      value: 'elementarySchool',
      content: event_t('leagueType.elementarySchool'),
    },
    { value: 'middleSchool', content: event_t('leagueType.middleSchool') },
    { value: 'highSchool', content: event_t('leagueType.highSchool') },
    { value: 'university', content: event_t('leagueType.university') },
    { value: 'club', content: event_t('leagueType.club') },
    { value: 'amateur', content: event_t('leagueType.amateur') },
    { value: 'pro', content: event_t('leagueType.pro') },
  ];

  const getLeagueTypeList = (): { value: LeagueType; content: string }[] => [
    { value: 'child', content: t('leagueType.child') },
    { value: 'elementarySchool', content: t('leagueType.elementarySchool') },
    { value: 'middleSchool', content: t('leagueType.middleSchool') },
    { value: 'highSchool', content: t('leagueType.highSchool') },
    { value: 'university', content: t('leagueType.university') },
    { value: 'club', content: t('leagueType.club') },
    { value: 'amateur', content: t('leagueType.amateur') },
    { value: 'pro', content: t('leagueType.pro') },
  ];

  const getVisibilityList = (): {
    value: VisibilityType;
    content: string;
  }[] => [
    { value: 'public', content: common_t('visibility.public') },
    { value: 'unlisted', content: common_t('visibility.unlisted') },
    { value: 'membership', content: common_t('visibility.membership') },
    { value: 'private', content: common_t('visibility.private') },
  ];

  const getLogTypeList = () => [
    { value: 'TEAM_SCORE', content: t('Logs.score') },
    { value: 'TEAM_SET_SCORE', content: t('Logs.setScore') },
    { value: 'TEAM_FOUL', content: t('Logs.foul') },
    { value: 'CHANGE', content: t('Logs.change') },
    { value: 'PARTICIPATE', content: t('Logs.participate') },
  ];

  const getPlayerTypeList = (): {
    value: MemberType;
    content: string;
  }[] => [
    { value: 'main', content: t('main') },
    { value: 'sub', content: t('sub') },
    { value: 'reserve', content: t('reserve') },
  ];

  const getControlThemeList = (theme: string) => {
    let totalControllers;

    switch (theme) {
      case 'scoreboardHasSet':
      case 'baseball':
        totalControllers = 3;
        break;
      default:
        totalControllers = 2;
        break;
    }

    return new Array(totalControllers).fill(0).map((_, index) => ({
      value: `${index + 1}`,
      content: t(`ControllerTheme.controller${index + 1}`),
    }));
  };

  const getRoundList = (sportType: SportType) => {
    let roundCount = 0;
    switch (sportType) {
      case 'baseball':
        roundCount = 9;
        break;
      case 'badminton':
      case 'basketball':
      case 'jokgu':
      case 'sepakTakraw':
      case 'soccer':
      case 'squash':
      case 'tableTennis':
      case 'volleyball':
        roundCount = 5;
        break;
      case 'taekwondo':
        roundCount = 4;
        break;
      case 'pickleball':
        roundCount = 3;
        break;
      default:
        break;
    }

    return new Array(roundCount).fill(0).map((_, index) => ({
      value: `${index + 1}`,
      content:
        sportType === 'soccer' || sportType === 'taekwondo'
          ? t(`round.${sportType}.${index + 1}`)
          : t(`round.${sportType}`, { round: `${index + 1}` }),
    }));
  };

  const getLeaderboardThemeList = () => [
    { value: 'default', content: event_t('list.theme.default') },
    { value: 'round', content: event_t('list.theme.round') },
  ];

  const channelCategory = (text: string) =>
    settings_t(`detailSettingCard.categoryList.${text}`);
  const getChannelCategoryList = (): {
    value: ChannelCategoryType;
    content: string;
  }[] => [
    { value: 'sportsFacilities', content: channelCategory('sportsFacilities') },
    { value: 'creator', content: channelCategory('creator') },
    { value: 'teamGroup', content: channelCategory('teamGroup') },
    { value: 'businessCompany', content: channelCategory('businessCompany') },
    { value: 'institution', content: channelCategory('institution') },
    { value: 'clubs', content: channelCategory('clubs') },
    { value: 'player', content: channelCategory('player') },
    { value: 'association', content: channelCategory('association') },
    { value: 'community', content: channelCategory('community') },
    { value: 'common', content: channelCategory('common') },
  ];

  return {
    getChannelCategoryList,
    getControlThemeList,
    getDateRangeOptionList,
    getVisibilityList,
    getLeagueTypeList,
    getLogTypeList,
    getPlayerTypeList,
    getPositionMenuList,
    getSortingOptionList,
    getSportTypeList,
    getStatusList,
    getEventEnvironmentList,
    getEventLeagueTypeList,
    getEventTypeList,
    getLeaderboardThemeList,
    getRoundList,
  };
};

export default useTranslationList;
